import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

// import "./layout.css"
// import "milligram/dist/milligram.css"
import "../components/milligram.css"
import "../components/custom.css"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import HomepageLayout from "./HomepageLayout"

const ContentContainer = styled.main`
  margin: 0.5em auto;
  max-width: 1000px;
  padding: 1em;
  line-height: 1.4;
  text-align: justify;
  grid-area: content;
`

const Header = styled.div`
  grid-area: header;
`

const LeftSide = styled.div`
  grid-area: nav;
  margin-left: 0.5rem;
  @media (max-width: 768px) {
    margin: 0;
  }
`

const RightSide = styled.div`
  grid-area: side;
  margin-right: 0.5rem;
  @media (max-width: 768px) {
    margin: 0;
  }
`

const PageFooter = styled.footer`
  grid-area: footer;
  background: peachpuff;
`

const PageContainer = styled.div`
  display: grid;

  grid-template-areas:
    "header header header"
    "nav content side"
    "footer footer footer";

  grid-template-columns: 200px 1fr 200px;
  grid-template-rows: auto 1fr auto;
  grid-gap: 10px;

  height: 100vh;
  @media (max-width: 768px) {
    grid-template-areas:
      "header"
      "nav"
      "content"
      "side"
      "footer";

    grid-template-columns: 1fr;
    grid-template-rows:
      auto /* Header */
      minmax(0px, auto) /* Nav */
      1fr /* Content */
      minmax(75px, auto) /* Sidebar */
      auto; /* Footer */
  }
`

const DefaultLayout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query DefaultLayoutSiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <PageContainer>
        <Header>
          <Navbar />
        </Header>
        <LeftSide />
        <ContentContainer>{children}</ContentContainer>
        <RightSide />
        <PageFooter>
          <Footer />
        </PageFooter>
      </PageContainer>
    </>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ({ children, pageContext }) => {
  if (pageContext.layout === "special") {
    return <>{children}</>
  }
  if (pageContext.layout === "homepage") {
    return <HomepageLayout>{children}</HomepageLayout>
  }
  return <DefaultLayout>{children}</DefaultLayout>
}
