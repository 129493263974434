import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Fade as Hamburger } from "hamburger-react"

import useToggle from "../utils/useToggle"

const MobileBurger = styled.li`
  @media (min-width: 768px) {
    display: none;
  }
  margin: 0;
`

const NestedDropdowns = styled.ul`
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  /* Reset list styles */
  list-style-type: none;
  margin-bottom: 10px;
  padding: 0;
  background-color: rgb(96, 108, 118);
`

const MenuItem = styled.li`
  @media (max-width: 768px) {
    width: 100%;
    display: ${({ show }) => (show ? "block" : "none")};
    // on mobile we want display block all the time
    ul {
      display: block;
    }
  }

  a {
    /* So whole area of li is clickable */
    display: inline-block;
    padding: 8px 16px;
  }
  div {
    padding: 8px 16px;
  }
  margin-bottom: 0 !important;

  /* Used to position the sub dropdown */
  position: relative;
  color: white;
  a {
    color: white;
    /* So full width of dropdown is clickable */
    width: 100%;
  }
  text-decoration: none;
  &:hover {
    background-color: var(--highlight-color);
  }

  // on desktop we want to change each ul to display: block on hover
  @media (min-width: 768px) {
    &:hover > ul {
      display: block;
    }
  }
`

const MenuDropDown = styled.ul`
  @media (max-width: 768px) {
    width: 100%;
  }
  width: 200px;

  background-color: rgb(96, 108, 118);

  /* Hidden by default */
  display: none;

  /* Reset styles */
  list-style-type: none;
  margin: 0;
  padding: 0;

  @media (min-width: 768px) {
    z-index: 9999;
    left: 0;
    position: absolute;
    top: 100%;
  }
`

const CenteredLink = styled(Link)`
  text-align: center;
`

const CenteredLabel = styled.div`
  text-align: center;
`

const MenuLink = ({ text, route, show }) => (
  <MenuItem show={show}>
    <CenteredLink to={route}>{text}</CenteredLink>
  </MenuItem>
)

const Navbar = () => {
  const [isOpen, setOpen] = useState(false)
  const [contentMenuOpen, toggleContentMenuOpen] = useToggle()

  return (
    <NestedDropdowns>
      <MobileBurger>
        <Hamburger toggled={isOpen} toggle={setOpen} color="white" />
      </MobileBurger>
      <MenuLink text="Home" route="/" show={isOpen} />
      <MenuItem show={isOpen} onClick={toggleContentMenuOpen}>
        <CenteredLabel>Content</CenteredLabel>
        <MenuDropDown>
          <MenuLink text="Tags" route="/tags" show={contentMenuOpen} />
          <MenuLink
            text="Category"
            route="/categories"
            show={contentMenuOpen}
          />
          <MenuLink text="Archive" route="/archive" show={contentMenuOpen} />
          <MenuLink text="Gallery" route="/gallery" show={contentMenuOpen} />
        </MenuDropDown>
      </MenuItem>
      <MenuLink text="About" route="/about" show={isOpen} />
      <MenuLink text="Contact" route="/contact" show={isOpen} />
    </NestedDropdowns>
  )
}

export default Navbar
