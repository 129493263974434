import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const MaxWidthWrapper = styled.div`
  background: lightgrey;
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  @media (min-width: 768px) {
    padding: 32px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`

const FooterLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 768px) {
    text-align: center;
    padding-bottom: 32px;
    align-items: center;
  }
`

const FooterRight = styled.div`
  display: flex;
  @media (max-width: 768px) {
    justify-content: space-around;
  }
`

const FooterColumn = styled.div`
  @media (min-width: 768px) {
    margin-left: 96px;
    padding-top: 8px;
  }
`

const FooterHeading = styled.h4`
  font-size: 14px;
  font-weight: 300;
`

const LinkGrid = styled.div`
  display: grid;
  gap: 6px;
  padding-top: 12px;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Title = styled(Link)`
  font-size: xx-large;
`

const SmallLink = styled(Link)`
  font-size: 14px;
`

const Copyright = styled.div`
  font-size: 12px;
  color: hsl(225deg, 12%, 40%);
  @media (max-width: 768px) {
    text-align: center;
    padding-top: 48px;
  }
`

const DesktopSpan = styled.span`
  @media (max-width: 768px) {
    display: none;
  }
`

const MobileSpan = styled.span`
  @media (min-width: 768px) {
    display: none;
  }
`

const Footer = () => (
  <MaxWidthWrapper>
    <FooterLeft>
      <div>
        <Title to={`/`}>TheYarnSite</Title>
      </div>
      <DesktopSpan>
        <Copyright>© 2020-present TheYarnSite. All Rights Reserved.</Copyright>
      </DesktopSpan>
    </FooterLeft>
    <FooterRight>
      <FooterColumn>
        <FooterHeading>Pages</FooterHeading>
        <LinkGrid>
          <SmallLink to={`tags`}>Tags</SmallLink>
          <SmallLink to={`categories`}>Categories</SmallLink>
          <SmallLink to={`gallery`}>Gallery</SmallLink>
          <SmallLink to={`archive`}>Archive</SmallLink>
          <SmallLink to={`/`}>Home</SmallLink>
          <SmallLink to={`/about`}>About</SmallLink>
        </LinkGrid>
      </FooterColumn>
      <FooterColumn>
        <FooterHeading>Links</FooterHeading>
        <LinkGrid>
          <SmallLink to={`/`}>Instagram</SmallLink>
          <SmallLink to={`/`}>RSS</SmallLink>
          <SmallLink to={`/contact`}>Contact</SmallLink>
        </LinkGrid>
      </FooterColumn>
    </FooterRight>
    <MobileSpan>
      <Copyright>© 2020-present TheYarnSite. All Rights Reserved.</Copyright>
    </MobileSpan>
  </MaxWidthWrapper>
)

export default Footer
